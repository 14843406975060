.responsive {
	&__hidden {
		display: none !important;
	}
	&__button-active {
		background: $success;
		color: $primary;
		border-color: $venmo;
		&:hover {
			color: $primary !important;
			border-color: $primary !important;
		}
	}

	&__segmented {
		border-color: transparent;
		padding: 4px 7px;
		&:hover {
			border-color: transparent !important;
			color: $primary !important;

			background-color: $bayarea-1;
		}
	}
}

.table-filter {
	&__column-visibility,
	&__service,
	&__status,
	&__activity,
	&__platform {
		padding: 4px 10px !important;
		margin: 0 !important;

		svg {
			margin-left: 0px;
		}
	}
}
.action-bar {
	&--fader {
		width: 100%;
	}
	&__compact {
		.cuboh-picker,
		.cuboh-select-selector {
			border: 1px solid transparent !important;
		}
		.timezone-select {
			.cuboh-select-selector {
				border: transparent;
			}
		}
	}
	&__location-select {
		.cuboh-select-selector {
			border: 1px solid transparent !important;
		}
	}
}

@media (max-width: 991px) {
	.timezone-select {
		padding-bottom: 2px;
	}
	.action-bar {
		&__location-select {
			padding-top: 2px;
		}
	}
}

@media (max-width: 1100px) {
	.cuboh-picker-panels {
		flex-direction: column !important;
	}
}

@media (min-width: 990px) and (max-width: 1071px) {
	.action-bar {
		&__compact {
			.cuboh-picker-input {
				input {
					width: 60px;
				}
			}
			.cuboh-select-selection-item {
				padding-inline-end: 7px !important;
			}
			.cuboh-select {
				.cuboh-select-arrow {
					font-size: 10px;
					right: 6px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.action-bar {
		&__location-select {
			width: 100%;
		}
		&__wrapper {
			padding: 0 !important;
			width: 100%;
		}
	}
	.cuboh-page-header-heading-extra {
		width: 100%;
		.cuboh-space-horizontal {
			width: 100%;
			.cuboh-space-item {
				width: 100%;
			}
		}
	}

	.responsive {
		&__filters {
			.table-filter {
				&__search,
				&__platform {
					margin: 2px 0;
					.cuboh-select-selector {
						width: 100% !important;
					}
					width: 100% !important;
					input {
						width: 100% !important;
					}
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.action-bar {
		&__location-select {
			min-width: 275px;
		}
	}
	.responsive {
		&__filters {
			.action-bar {
				&__wrapper {
					button {
						margin: 0px 2px;
					}
				}
			}
			.table-filter {
				&__search,
				&__platform,
				&__column-visibility {
					margin: 0px 2px;
				}
			}
		}
	}
}

@media (max-width: 576px){
	// On apple branded devices, inputs with font-size lower than 16px cause the app to focus(zoom) on the input when on small screens and do not un-focus out when completed on 
	input{
		font-size:16px !important
	}
}
