.draggable-card {
	&__menu-edit {
		.cuboh-card-head-title {
			cursor: auto !important;
		}
	}
	.cuboh-card-extra {
		margin-right: 20px;
	}

	&__title-edit {
		color: $primary !important;
		span:nth-child(1) {
			position: relative !important;
			right: 1px !important;
			top: 1px !important;
			opacity: 0;
			transition: display 200ms;
		}
		span:nth-child(2) {
			opacity: 0;
			transition: display 200ms;
		}
		span:nth-child(3) {
			right: 40px !important;
			transition: right 200ms;
		}
	}
	&__title-view {
		color: $primary !important;
		span:nth-child(1) {
			opacity: 1;
			transition: display 200ms;
		}
		span:nth-child(2) {
			opacity: 1;
			transition: display 200ms;
		}
		span:nth-child(3) {
			transition: all 200ms;
		}
	}
	&__popover-status:nth-child(odd) {
		background-color: #f4f4f4;
		padding: 12px;
	}
	&__popover-status:nth-child(even) {
		background-color: #fff;
		padding: 12px;
	}

	&__active-drag {
		-webkit-box-shadow: 0px 3px 6px 3px rgb(0 0 0 / 40%) !important;
		-moz-box-shadow: 0px 3px 6px 3px rgb(0 0 0 / 40%) !important;
		box-shadow: 0px 3px 6px 3px rgb(0 0 0 / 40%) !important;
		transition: box-shadow 0.2s linear;
		transition-timing-function: ease-in-out;
	}

	.cuboh-badge {
		width: 100%;
		.cuboh-badge-dot {
			right: 11px;
			top: 6px;
		}
	}
	.cuboh-switch-small {
		min-width: 28px;
		max-width: 28px;

		svg {
			position: relative;
			right: 3px;
		}
	}
	.cuboh-card-head {
		border-bottom: 0px !important;
	}
	.cuboh-card-head-title {
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
		&:active {
			&:active {
				cursor: grabbing;
				cursor: -moz-grabbing;
				cursor: -webkit-grabbing;
			}
		}
	}
	.cuboh-card-body {
		padding-top: 0px;
		padding-bottom: 0px;
		min-height: 116px;
	}
	&__button {
		padding: 0px !important;
		height: auto;
	}
	&__title-view {
		margin-bottom: 0px !important;
	}
	&__integrations {
		padding: 12px 0px;
		width: 100%;
	}
	&__integration {
		&-modified {
			border: solid 3px $warning !important;
		}
		&-published {
			border: solid 3px $success !important;
		}
		&-in_progress {
			border: solid 1px $info !important;
			animation: pulse 2s infinite;
		}
		&-failed {
			border: solid 3px $danger !important;
		}
        &-inactive {
			border: solid 3px $gray !important;
		}
	}
	&__menu-status-popover-modified {
		.cuboh-card-head {
			background: $warning;
			color: $primary;
		}
		.cuboh-card-body {
			padding: 0px;
		}
	}
    &__menu-status-popover-inactive {
		.cuboh-card-head {
			background: $gray;
			color: $primary;
		}
		.cuboh-card-body {
			padding: 0px;
		}
	}
	&__menu-status-popover-in-progress,
	&__menu-status-popover-in {
		.cuboh-card-head {
			background: $info;
			color: $primary;
		}
		.cuboh-card-body {
			padding: 0px;
		}
	}
	&__menu-status-popover-failed {
		.cuboh-card-head {
			background: $danger;
			color: $primary;
		}
		.cuboh-card-body {
			padding: 0px;
		}
	}
	&__menu-status-popover-published {
		.cuboh-card-head {
			background: $success;
			color: $primary;
		}
		.cuboh-card-body {
			padding: 0px;
		}
	}

	&__push-summary {
		max-width: 250px;
	}

	&__merchant-title-popover {
		h5 {
			margin-bottom: 0px;
		}
	}

	&__avatar-group {
		margin-right: 5px;
		& > *:not(:first-child) {
			margin-inline-start: -15px;
		}

		.cuboh-avatar {
			&:hover {
				cursor: pointer;
				transform: scale(1.2);
				transition: transform 0.2s;
			}
		}
	}

	.cuboh-card-head {
		padding: 0px;
		min-height: 0px;
	}

	.cuboh-card-body {
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
	.cuboh-card-actions {
		border-top: 0px;
		border-top-right-radius: 0px;
		border-top-left-radius: 0px;
		padding-left: 3px;
		padding-right: 3px;
	}
}

.merchant-container {
	padding: 10px 0;

	&__merchant-title {
		margin-bottom: 0 !important;
		color: $primary !important;
	}

	&__add-menu-button {
		background-color: #fff !important;
		border-style: dashed !important;
		border-color: $primary;
		color: $primary;
	}
	&__add-menu-button-icon {
		position: relative;
		top: -1px;
		left: -1px;
	}
}

.menu-card {
	&__back-button {
		padding-left: 0px;
		padding-right: 0px;
		color: $primary;
		svg {
			position: relative;
			top: -1px;
		}
	}
	&__tabs {
		box-shadow: 3px 3px 3px -3px rgba(0, 0, 0, 0.4);
	}
	&__title-col {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__title {
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 16px;
		padding-bottom: 16px;
		overflow: hidden;
		span:nth-child(1) {
			position: relative;
			right: 7px;
			top: 1px;
		}
		span:nth-child(2) {
			position: relative;
			right: 22px;
			top: 1px;
		}
		span:nth-child(3) {
			position: relative;
			right: 23px;
		}
	}
	&__no-linked-merchants {
		display: block;
		height: 30px;
		position: relative;
		top: 1px;
		padding: 0px;
	}
	&__linked-merchants {
		width: 100%;
		.cuboh-select-selector {
			border: 0 !important;
			padding: 0 !important;

			.cuboh-select-selection-placeholder {
				color: $primary;
			}
		}

		.cuboh-select-arrow {
			color: $primary;
			inset-inline-start: 142px;
		}
	}
	&__linked {
        width: max-content !important;
        font-size: small !important;
		padding: 0;
	}

	&__delete-button {
		color: $primary;
		box-shadow: none;
		width: 100% !important;
		&:hover {
			background-color: $danger !important;
			color: $primary !important;
			transition: all 0.3s;
		}

		svg {
			position: relative;
			top: -1px;
		}
	}
	&__edit-button {
		color: $primary;
		box-shadow: none;
		width: 100% !important;

		&:hover {
			background-color: $warning !important;
			color: $primary !important;
			transition: all 0.3s;
		}
		svg {
			position: relative;
			top: -1px;
		}
	}
	&__preview-button {
		color: $primary;
		box-shadow: none;
		width: 100% !important;
		&:hover {
			background-color: $info !important;
			color: $primary !important;
			transition: all 0.3s;
		}

		svg {
			position: relative;
			top: -1px;
		}
	}
	&__validate-button {
		color: $primary;
		box-shadow: none;
		width: 100% !important;
		&:hover {
			background-color: $microdose !important;
			color: $primary !important;
			transition: all 0.3s;
		}

		svg {
			position: relative;
			top: -1px;
		}
	}
	&__publish-button {
		color: white;
		box-shadow: none;
		width: 100% !important;
		&:hover {
			transition: all 0.3s;
		}

		svg {
			position: relative;
			top: -1px;
		}
	}
	&__duplicate-button {
		color: $primary;
		box-shadow: none;
		width: 100% !important;
		&:hover {
			background-color: $microdose !important;
			color: $primary !important;
			transition: all 0.3s;
		}

		svg {
			position: relative;
			top: -1px;
		}
	}
	&__seeHours-button {
		color: $primary;
		box-shadow: none;
		width: 100% !important;
		&:hover {
			background-color: $venmo-1 !important;
			color: $primary !important;
			transition: all 0.3s;
		}

		svg {
			position: relative;
			top: -1px;
		}
	}
}

.fade-enter {
	opacity: 0;
}
.fade-exit {
	opacity: 1;
}
.fade-enter-active {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
}
.fade-enter-active,
.fade-exit-active {
	transition: opacity 300ms;
}

@keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 $info-1;
	}
	70% {
		-webkit-box-shadow: 0 0 0 3px $info;
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 $info;
	}
}
