.kiosk {
	&__modal {
		min-width: 70%;
		&__title {
			margin-top: 15px !important;
		}
		&__col {
			text-align: center;
		}
		&__button {
			min-width: 100%;
			height: 45px;
			&__half {
				min-width: 50%;
			}
		}
		&__select {
			min-width: 250px;
		}
	}
	&__admin-modal {
		&__button {
			width: 240px;
		}
		&__steps {
			.cuboh-steps-item
				> .cuboh-steps-item-container
				> .cuboh-steps-item-tail::after {
				background: transparent !important;
			}
			.cuboh-steps-item > .cuboh-steps-item-container > .cuboh-steps-item-icon {
				background: transparent !important;
			}
		}
	}
	&__home {
		overflow-y: hidden;
		min-height: 95vh;
	}
	&__home-button {
		min-height: 95vh;
	}
	&__home-title {
		color: black;
	}
}

.network-disconnect-modal {
	&__title {
		margin-bottom: 0px !important;
	}
}
