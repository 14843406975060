.live-orders {
    &__card {
        overflow: scroll;
        height: 250px;
        width: 400px;
    }
}

.widget-group-cb {
	display: block;
}

.time-paused-widget-collapse{
    .cuboh-collapse-expand-icon {
        margin-top:12px;
    }
}
