.auth-form {
  &__container {
    height: 100%;

    &--light {
      background: $white !important;
    }

    &--dark {
      background: $primary !important;

      .cuboh-typography {
        color: white;
      }
    }
  }

  &__footer {
    text-align: center;

    &--light {
      background: $white !important;
    }

    &--dark {
      background: $primary !important;
    }
  }
}

.cuboh-spin-container,
.cuboh-spin-nested-loading {
  height: 100%;
}

.cuboh-form {
  &__password {
    margin-bottom: 16px;
  }
}

.cuboh-input-affix-wrapper {

  &-focused {
    .cuboh-input-prefix {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}