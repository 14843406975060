.integration {
	&--tabs {
		margin-bottom: 32px;
	}

	&--cardlist {
		.ant-card-body {
			min-height: 160px;
		}
	}
}

.platforms,
.integrations {
	.cuboh-collapse {
		background: white;
		margin: 20px 0px;
		border-radius: 2px !important;
	}
	.cuboh-collapse-content,
	.cuboh-collapse-content-box {
		border-radius: 0px !important;
	}
	.cuboh-collapse-item {
		border-radius: 0 0 2px 2px !important;
	}
}

#integration-activation {
	margin: 24px 0px;

	.cuboh-form-item,
	.cuboh-form-item-with-help {
		margin-bottom: 32px;
	}

	.cuboh-form-item-explain {
		margin-top: 5px;
	}

	.cuboh-input {
		height: 38px;
		padding: 4px 11px;
	}

	.cuboh-input-affix-wrapper {
		padding: 0;
	}

	.cuboh-input-prefix-wrapper {
		padding: 4px 11px;
	}
}

.integration-external-location-link {
	margin-top: 20px;
	border-radius: 9px !important;
	border-color: $primary !important;
}

.integration-external-location-checkbox {
	margin-top: 20px;
	border-radius: 9px !important;
	border-color: $primary !important;
}

.cuboh-v1-integration-card {
	.cuboh-card-body {
		height: 175px;
	}
}

.cuboh-integration-card {
	.cuboh-card-body {
		font-size: 12px;
		height: 150px;
	}
}

.cuboh-referral-card {
	.cuboh-card-body {
		font-size: 12px;
		height: 165px;
	}
}

.connection-div {
	display: flex;
	align-content: center;
	justify-content: center;
}

.cuboh-connection-card {
	min-width: 50%;
	display: flex;
	justify-content: center;
}

.integration-success-check {
	svg {
		background-color: $success;
		color: $primary;
		border-radius: 100%;
	}

	.cuboh-result-title {
		display: none;
	}

	.cuboh-result-icon {
		margin-bottom: 0px !important;
	}
}

.integration-success {
	&__steps {
		.cuboh-steps-item-title {
			top: 13px !important;
		}

		.cuboh-steps-item-icon {
			background-color: transparent !important;
		}

		.cuboh-result {
			padding: 0 !important;
			margin-bottom: 0 !important;
		}
	}

	&__title {
		text-align: center;
	}

	&__helplist {
		border-radius: 2px;

		a {
			color: $primary !important;
		}

		&:hover {
			background-color: $microdose;
			transition: border-color 0.3s, background 0.3s,
				padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
		}
	}
}

.cuboh-modal {
	&__integration-title {
		margin-bottom: 0px !important;
	}
}
