.spin-title {
  text-align: center;

  .ant-spin {
    padding: 20px;
  }

  h1 {
    margin-bottom: 0;
  }
}

.cuboh-card-head {
  border-bottom: 0px;
}

.typeform-sidetab-button {
  background-color: $secondary !important;
  width: 150px !important;
  top: 75% !important;
}

.positive{
  .cuboh-statistic-content-value,
  .cuboh-statistic-content-prefix,
  .cuboh-statistic-content-suffix {
  color: $success;
  font-weight: 700;
  }
}

.negative{
  .cuboh-statistic-content-value,
    .cuboh-statistic-content-prefix,
    .cuboh-statistic-content-suffix {
  color: $danger;
  font-weight: 800;
  }
}

.analytics-switch{
  &-on{
     background-color: $secondary;
		background-image: 'none';
  }
}

.cuboh-statistic-title{
  margin-top:4px;
}


.analytics-export-link-no-pointer-events{
  pointer-events: 'none';
}
